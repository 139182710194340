import axios from "axios"

// the base url for media upload PRoduction
export   const APIURL_MEDIA ="https://api.shs-app.es/beyondapi/api/media/upload"
// the base url for media upload PRoduction

// export const APIURL_MEDIA = "https:///psd2htmlx.com/beyondapi/api/media/upload"

//apply base url for axios
export  const API_URL = "https://api.shs-app.es/beyondapi/api"

// for local url
// export  const API_URL = "https:///psd2htmlx.com/beyondapi/api"

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}
